@use '../../Styles/variables.scss' as vars;
@use '../../Styles/mixins.scss' as mixins;
@use '../../Styles/globals.scss';

.glassCard {
    cursor: pointer;
    @include mixins.glass-style;
    padding: 1rem;
    margin-top: 1rem;
    width: fit-content;
}

.container {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%; 
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr auto; 
    gap: 0.5vh;

    &.details-hidden {
        grid-template-columns: 1fr;
    }
}

.weatherIcon {
    width: 4vw;
    height: 4vw;
}

.temperature {
    margin: auto;
    font-size: 1.5vw;
    font-weight: bold;
}

.weatherDetails {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1vw;

    p {
        margin: 0.5rem 0;
        font-size: 0.7vw;
        text-align: start;
        color: yellowgreen;
    }

    span {
        font-size: 0.75vw;
        font-weight: bold;
    }
}

@media (max-width: 576px) {
    .glassCard {
        width: 100%; 
        padding: 0.5rem;
        display:none
    }

    .gridContainer {
        grid-template-columns: 1fr;
        gap: 1vh;
    }

    .weatherIcon {
        width: 8vw;
        height: 10vw;
    }

    .temperature {
        font-size: 3vw;
    }

    .weatherDetails {
        margin-left: 2vw;

        p {
            font-size: 2vw;
        }

        span {
            font-size: 2.2vw;
        }
    }
}
