/* SettingsOverlay.module.scss */

.modalDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
    animation: fadeIn 0.3s ease-in-out;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modalHeader {
    background-color: #0c1445;
    border-radius: 15px 15px 0 0;

}

.modalTitle {
    color: white;
    padding: 1vh;
    text-align: center;
    display: flex;
    align-items: center; /* Center vertically */
}

.modalBody {
    background-color: #fff7e6;
    width: 30vw;
    height: 36vh;
    padding: 1.5vh;
    border-radius: 0 0 15px 15px;
}

.formGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
}

.formControl {
    margin-bottom: 1rem;
    color: black;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 0.5vw;
    width: 12vw;
    height: 10vh;
    resize: none;
    font-size: 1.6vh;
}

.formLabel {
    color: black;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 1.8vh;
}

.buttonClose {
    background-color: red;
    padding: 0.375rem 0.75rem;
    border: none;
    border-radius: 10px;
    margin: 1vh;
}

.buttonSave {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    &:disabled {
        background-color: #c0c8cf;
        border-color: #c0c8cf;
    }
    border: none;
    border-radius: 10px;
    padding: 0.375rem 0.75rem;
}

.spinner {
    margin-left: 5px;
}

.footer {
    justify-content: center;
}
.savedMessage {
    text-align: center;
    margin-bottom: 1vh;
    margin-top: -1vw;
    padding: 0.5vh;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border: 1px solid transparent;
    border-radius: 0.25vh;
    width: 30vw;
    height: 2vh;
    margin-left: -0.8vw;
}


.historyIcon {
    margin-left: auto; 
    margin-right: 10px; 
    cursor: pointer; 
    font-size: 1vw; 
}
