

$glass_background: rgba(17, 25, 40, 0.75);
$glass_border: rgba(255, 255, 255, 0.125);
$glass_blur: 16px;
$glass_saturate: 180%;
$border_radius: 12px;
$highlight_color: #FFFFFF;
$accent_color: #FFB703;
$desktop_breakpoint: 768px;
