.resultsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.poemTitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.poemContent {
  font-size: 1.5vw;
  text-align: left;
  width: 100%;
  max-width: 60vw;
  white-space: pre-wrap;
  padding: 15px;
}

.loadingSpinner {
  margin-top: 20px;
}

.backButton {
  margin-top: 20px;
  background-color: gold;
  width: 10vw;
  height: 4vh;
  border-radius: 2vw;
  color: black;
  font-weight: 800;
}


@media screen and (max-width: 768px) {
  .resultsContainer {
      padding: 10px; 
  }

  .poemTitle {
      font-size: 20px;
      margin-bottom: 5px;
  }

  .poemContent {
      font-size: 5vw; 
      width: 90%; 
      max-width: 90%; 
      padding: 10px; 
  }

  .loadingSpinner {
      margin-top: 10px; 
  }

  .backButton {
      margin-top: 10px;
      width: 50%; 
      max-width: 200px; 
      height: 40px; 
      border-radius: 20px; 
  }
}
