@use '../../Styles/variables.scss' as vars;
@use '../../Styles/globals' as *;

.localeContainer {
    width: 10vw; 
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-left: 2vw;

    h1 {
        font-weight: 700;
        color: vars.$highlight_color;
        font-size: 1.5vw; /* Adjust font size */
    }

    p {
        font-size: 1vw;
        margin-top: auto;
        text-align: start;
    }

    @media (max-width: 576px) {
        width: 20vw; 
        display:none;

        h1 {
            font-size: 4vw; 
        }

        p {
            font-size: 2vw; 
        }
    }
}
