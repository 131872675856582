@use 'variables' as vars;
@import url("https://fonts.googleapis.com/css?family=Montserrat:wght@300;700&display=swap");

*{
  font-family: "Montserrat", sans-serif;
  color: vars.$highlight_color;
}


body {
  // background-image: url('https://images.unsplash.com/photo-1519681393784-d120267933ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1124&q=100');
  // background-position: center;
  // background-size: cover;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  text-align: center;
  max-width: 700px;
  width: 100%; 
  @media (max-width: 768px) {
    padding: 0.5rem;
}
}
