/* Dark sky gradient background */
.sky {
    min-height: 120vh;
    background: linear-gradient(to bottom, #0a0f0d 0%, #1e3c72 100%);
    position: relative;
    overflow: hidden;
  }
  
  /* Blinking stars */
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  .star {
    width: 2px;
    height: 2px;
    background: white;
    border-radius: 50%;
    position: absolute;
    opacity: 0.2;
    animation: blink 2s infinite ease-in-out;
  }
  