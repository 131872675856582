@use '../../Styles/variables' as vars;
@use 'sass:color';

.container {
    margin-top: 3vh;
    margin-left: 1vw;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5vh;
}

.categoryButton {
    text-align: left;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 1vw;
    cursor: pointer;
    background-color: vars.$glass_background;
    border: none;
    padding: 0.5vw;
    border-radius: 3vw;
    width: 100%;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &::before {
        content: '▼';
        display: inline-block;
        margin-left: 1vh;
        transition: transform 0.3s ease;
    }

    &.open::before {
        transform: rotate(-180deg);
    }
}

.card {
    background-color: vars.$glass_background;
    border: 1px solid vars.$glass_border;
    border-radius: 3vw;
    box-shadow: 0 2vw 8vw 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(vars.$glass_blur) saturate(vars.$glass_saturate);
    -webkit-backdrop-filter: blur(vars.$glass_blur) saturate(vars.$glass_saturate);
    margin-bottom: 3vh;
    min-width: 45vw;
    text-align: start;
}

.h2 {
    font-size: 2vh;
    margin-left: 1vw;
    padding: 0.6vw;
    color: vars.$highlight_color;
}

.formCheck {
    display: flex;
    align-items: center;
    padding: 2vh;
    margin: 2vh;
    font-size: 1.5vw;

    input {
        accent-color: vars.$accent_color;
        margin-right: 2vw;
        width: 1vw;
        height: 1vw;
    }

    label {
        color: vars.$highlight_color;
        margin-left: 0.1vw;
    }
}

.collapseExpandButtons {
    margin-top: 1vh;
    margin-left: 0.5vw;
    margin-bottom: 1vh;
    width: 3vw;
}

.card-body {
    padding: 1vh;
    align-items: start;
}

.selectedProductsLine {
    margin-top: 2vh;
    padding: 2vh;
    font-size: 2.4vh;
}

.nextButtonContainer {
    margin-top: 2vh;
    display: flex;
    justify-content: space-between;
}

.nextButton {
    background-color: vars.$accent_color;
    border-color: vars.$accent_color;
    color: #fff;
    font-size: 2.4vh;
    padding: 2vh 4vw;
    border-radius: 3vw;

    &:hover,
    &:focus {
        background-color: color.adjust(vars.$accent_color, $lightness: -10%);
        border-color: color.adjust(vars.$accent_color, $lightness: -10%);
    }
}

@media (max-width: #{vars.$desktop_breakpoint}) {
    .container {
        margin-top: 2vh;
        margin-left: 0;
    }

    .gridContainer {
        grid-template-columns: 1fr;
        gap: 1vh;
    }

    .categoryButton {
        gap: 2vw;
        padding: 1vw;
        width: 100%;
    }

    .card {
        min-width: 90vw;
        box-shadow: 0 1vw 4vw 0 rgba(31, 38, 135, 0.37);
    }

    .h2 {
        font-size: 2.5vh;
        margin-left: 2vw;
    }

    .formCheck {
        padding: 1vh;
        margin: 1vh;
        font-size: 5vw;

        input {
            margin-right: 4vw;
            width: 5vw;
            height: 5vw;
        }

        label {
            margin-left: 1vw;
        }
    }

    .collapseExpandButtons {
        width: 5vw;
    }

    .card-body {
        padding: 2vh;
    }

    .selectedProductsLine {
        font-size: 3vh;
    }

    .nextButton {
        font-size: 3vh;
        // padding: 2vh 5vw;
        margin-right: 5vw;
    }

    .nextButtonContainer {
        justify-content: center;
    }
}
