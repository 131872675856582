@use './Styles/globals' as *;
@use './Styles/variables' as vars;
@use './Styles/mixins.scss' as mixins;

.glass-card {
    @include mixins.glass-style;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: vars.$border_radius;
}


.body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 2rem;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
}
