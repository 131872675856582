// HistoryOverlay.module.scss


.modalDialog {
    position: fixed;
    top: 55vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 1050;
    width: 90vw;  
    height: 60vh;  
    animation: fadeIn 0.3s ease-in-out;
    border-radius: 10px;  
    overflow: hidden;  
    .modal-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;  
    }
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 1040; /* Make sure the backdrop is behind the modal */
  }
  
.modalHeader {
    background-color: #0c1445;
    border-bottom: 1px solid #dee2e6;
    text-align: center;
    padding: 0.5vw;

    .modal-title {
        color: white;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.modalBody {
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto;  
    background-color: #fff;  
    .table {
        width: 100%;  
        th, td {
            color: black;  
            text-align: center;  
            vertical-align: middle;
        }
        
        tr:nth-child(even) {
            background-color: #d0deed; 
        }

        tr:nth-child(odd) {
            background-color: #c6ccee; 
        }

        textarea {
            width: 100%;
            height: auto;  
            border: none;
            background-color: transparent;
            color: black;  
            text-align: left;  
            &:focus {
                outline: none;
            }
        }
    }
}

.select{
    background-color: #0c1445;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.closeIcon {
    color: white;
    cursor: pointer;
    position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    right: 2vw; 
}
