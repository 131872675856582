/* DaySky.css */

.day-sky {
    min-height: 120vh;
    background: linear-gradient(to bottom, #87CEFA 0%, #fdfd96 100%);
    position: relative;
    overflow: hidden;
}

.sun {
    width: 80px;
    height: 80px;
    background: radial-gradient(circle, yellow 60%, transparent 70%);
    position: absolute;
    top: 10%;
    right: 10%;
    border-radius: 50%;
    box-shadow: 0 0 40px yellow;
}

@keyframes drift {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(110%);
    }
}

.cloud {
    background: white;
    border-radius: 50%;
    position: absolute;
    box-shadow: 20px 5px 0px 5px white, 
                50px 10px 0px 10px white,
                70px 15px 0px 15px white;
    animation: drift linear infinite;
    filter: blur(3px);
}

/* Larger screens */
@media (min-width: 600px) {
    .sun {
        width: 100px;
        height: 100px;
        box-shadow: 0 0 50px yellow;
    }

    .cloud {
        box-shadow: 30px 10px 0px 10px white, 
                    70px 20px 0px 20px white,
                    110px 30px 0px 30px white;
        filter: blur(5px);
    }
}

/* Larger screens (even wider) */
@media (min-width: 1024px) {
    .sun {
        width: 120px;
        height: 120px;
        box-shadow: 0 0 60px yellow;
    }

    .cloud {
        box-shadow: 40px 15px 0px 15px white, 
                    90px 30px 0px 30px white,
                    130px 45px 0px 45px white;
        filter: blur(7px);
    }
}
