/* HeaderComponent.module.scss */

.header {
  width: 100vw;
  background-color: #0c1445;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  overflow: hidden;
  height: 6vh;
}

.brand {
  display: flex;
  align-items: baseline;
  color: white;
  font-size: 1.5vw;
  font-weight: bold;
  text-decoration: none;
  margin-left: 3vw;
  margin-top: 0.8vw;
}

.iconLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1vw;
  height: auto;
}

.icon {
  color: white;
  font-size: 24px;
  margin-right: 1vw;
  margin-top: -3vh;
}

.versionMsg {
  font-size: 0.6vw;
  align-self: baseline;
  margin-left: 0.3vw;
}

@media (max-width: 576px) {
  .brand {
      font-size: 5vw;
  }
  
  .iconLink {
      display: none;
  }

  .versionMsg {
      font-size: 2vw;
  }
  .header {
    width: 110vw;
    margin-left: 2vw;
  }
  .brand {
    margin-left: 42vw;
    margin-top: 1.4vh;

  }
}
